import React from 'react';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { GatsbyBrowser } from 'gatsby';
import { globalStyles } from 'shared/styles/globalStyles';

import { theme } from 'shared/styles/theme/theme';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      {element}
    </ThemeProvider>
  );
};
