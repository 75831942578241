import { css } from '@mui/material';
import InterVariable from '../../../static/fonts/Inter-VariableFont_slnt,wght.woff2';

export const globalStyles = css`
  body,
  html,
  #___gatsby {
    height: 100%;
  }
  body {
    overflow-x: hidden;
  }
  @font-face {
    font-family: 'Inter';
    font-weight: 300 400 500 600 700;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url(${InterVariable}) format('woff2');
  }
  table, th, td {
    border: 1px solid #ECECEC;
    border-collapse: collapse;
    padding: 5px;
  }
  th {
    background-color: #4F4F4F
  }
`;
