export const shape = {
  borderRadius: {
    100: '100px',
    58: '58px',
    leftPill: '50% 0 0 50%',
    rightPill: '0 50% 50% 0',
    topPill: '50% 50% 0 0',
    bottomPill: '0 0 50% 50%',
  },
};
