import { graphql, useStaticQuery } from 'gatsby';

const useNavigation = () => {
  const navigationData: Queries.NavigationObjectQuery = useStaticQuery(graphql`
    query NavigationObject {
      contentfulNavigationMenu {
        menuItems {
          ... on ContentfulCareersPage {
            slug
            internalName
          }
          ... on ContentfulCompanyPage {
            slug
            internalName
          }
          ... on ContentfulPracticePage {
            slug
            internalName
          }
          ... on ContentfulPostIndexPage {
            slug
            internalName
          }
          ... on ContentfulCaseStudyIndexPage {
            slug
            internalName
          }
        }
      }
    }
  `);

  return navigationData;
};

export default useNavigation;
