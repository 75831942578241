import React from 'react';
import { Box, Button, styled } from '@mui/material';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
} from 'shared/components/icons';

interface Props {
  facebookLink: string | null;
  instagramLink: string | null;
  linkedInLink: string | null;
}

const SocialLinks = ({ facebookLink, instagramLink, linkedInLink }: Props) => {
  return (
    <SocialsContainer>
      {facebookLink && (
        <Button href={facebookLink} variant="socialButton">
          <FacebookIcon />
        </Button>
      )}
      {instagramLink && (
        <Button href={instagramLink} variant="socialButton">
          <InstagramIcon />
        </Button>
      )}
      {linkedInLink && (
        <Button href={linkedInLink} variant="socialButton">
          <LinkedInIcon />
        </Button>
      )}
    </SocialsContainer>
  );
};

export default SocialLinks;

const SocialsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  padding: '48px 0',

  '& > a': {
    width: 70,
  },

  '&:after': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    right: 'calc(50% - 50vw)',
    width: '100vw',
    height: 1,
    backgroundColor: theme.palette.primary.main,
  },

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'center',
    padding: 0,

    '&:after': {
      display: 'none',
    },
  },
}));
