import React from 'react';

export const PlusIcon: React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
> = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1654_330)">
        <path
          d="M35 17H0"
          stroke="currentColor"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          stroke="currentColor"
          d="M17 35V0"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1654_330">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PlusIcon.defaultProps = {
  height: 35,
  width: 35,
  fill: 'currentColor',
  stroke: 'currentColor',
};
