import { Components, Theme } from '@mui/material';

export const MuiCard: Components<Theme>['MuiCard'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        background: theme.palette.background.transparent,
        height: '100%',
      };
    },
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => {
        return {
          border: `1px solid ${theme.palette.primary.main}`,
        };
      },
    },
  ],
};

export const MuiCardActionArea: Components<Theme>['MuiCardActionArea'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover, &:focus': {
          '& .MuiCardActionArea-focusHighlight': {
            width: '100%',
            height: '100%',
            background: theme.palette.primary.yellow,
            opacity: 0.4,
          },
        },
      };
    },
  },
};
