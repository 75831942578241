/// <reference path='../../types/theme.d.ts' />

import { createTheme, ThemeOptions } from '@mui/material';
import {
  palette,
  typography,
  shape,
  components,
  zIndex,
  breakpoints,
} from './index';

const themeOptions: ThemeOptions = {
  typography: {
    ...typography,
  },
  palette,
  shape,
  zIndex,
  breakpoints,
};

export const theme = createTheme({
  ...themeOptions,
  components: {
    ...components,
  },
});
