export const typography = {
  fontFamily: ['Inter', 'sans-serif'].join(','),
  h1: {
    fontSize: '2.1875rem',
    lineHeight: 1.1,
    fontWeight: 700,
  },
  h2: {
    // Section headline
    fontSize: '1.875rem',
    lineHeight: 1.1,
    fontWeight: 700,
  },
  h3: {
    // Individual accordion headline
    fontSize: '1.125rem',
    fontWeight: 500,
  },
  h4: {
    // Card Headings
    fontSize: '1.125rem',
    lineHeight: 1.31,
    fontWeight: 500,
  },
  h5: {
    // Individual value component headline - maps to h3
    fontSize: '1.75rem',
    fontWeight: 700,
    lineHeight: 1.23,
  },
  h6: {
    // Individual service component headline - maps to h3
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: 1.23,
  },
  h1Small: {
    // Contact page h1
    fontSize: '1.5625rem',
    fontWeight: 700,
    lineHeight: 1.1,
  },
  body1: {
    fontSize: '.9375rem', // main body
    fontWeight: 400,
    lineHeight: 1.4,
  },
  body2: {
    // hero body
    fontSize: '1.125rem',
    fontWeight: 400,
  },
  body3: {
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  bullet1: {
    fontSize: '.9375rem',
    fontWeight: 500,
  },
  caption: {
    fontSize: '1rem',
  },
  button: {
    fontWeight: 400,
  },
  button2: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  footerLink: {
    fontSize: '.9375rem',
  },
  quote1: {
    fontSize: '.9375rem',
    fontWeight: 500,
    lineHeight: 1.29,
    fontStyle: 'italic',
  },
  quote2: {
    fontSize: '1.5rem',
    fontWeight: 300,
    lineHeight: 1.5,
    fontStyle: 'italic',
  },
  quote3: {
    fontSize: '1.5rem',
    lineHeight: 1.5,
    fontWeight: 300,
    fontStyle: 'italic',
  },
  pullQuote: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
    lineHeight: 1.15,
  },
  attribution: {
    fontSize: '.9375rem',
    fontStyle: 'italic',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  tag: {
    fontSize: '.9375rem',
    letterSpacing: 1,
    fontWeight: 500,
  },
  blogCta: {
    fontSize: '1.25rem',
    fontWeight: 400,
    fontStyle: 'italic',
    lineHeight: 1.4,
  },
};
