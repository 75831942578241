import React, { useEffect } from 'react';
import useFooter from 'hooks/useFooter';
import { styled, Stack, Box, List, ListItem, Typography } from '@mui/material';
import { Link } from 'gatsby';
import LogoGif from '../../../images/studion-animation.gif';
import { StudionLogoVertical } from '../icons';
import SocialLinks from './components/SocialLinks';
import { useInView } from 'react-intersection-observer';

const Footer = () => {
  const { contentfulFooter } = useFooter();
  const [formRef, formInView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const [animationRef, animationInView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (formInView) {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        if (window.hbspt && contentfulFooter) {
          window.hbspt.forms.create({
            portalId: contentfulFooter.hubSpotFormPortalId,
            formId: contentfulFooter.hubSpotFormFormId,
            region: contentfulFooter.hubSpotFormRegion,
            target: '#newsletter-form',
          });
        }
      });
    }
  }, [formInView]);

  return (
    <footer>
      {contentfulFooter && (
        <Container>
          <InnerContainer
            p={{ xs: '0 0 34px', lg: '14px 70px 76px 0' }}
            flex={1}
          >
            <Stack height="100%" justifyContent="space-between">
              <Stack alignItems={{ lg: 'center' }}>
                <GifContainer ref={animationRef}>
                  {animationInView && <img alt="Studion Logo" src={LogoGif} />}
                </GifContainer>
                <StudionLogoVertical />
              </Stack>
              {contentfulFooter.links && (
                <LinkList>
                  {contentfulFooter.links.map((link) => {
                    if (!link || !link.slug) return;
                    return (
                      <LinkItem key={`${link.slug}-${Math.random()}`}>
                        <FooterLink to={`/${link.slug}`}>
                          {link.pageName}
                        </FooterLink>
                      </LinkItem>
                    );
                  })}
                </LinkList>
              )}
            </Stack>
          </InnerContainer>
          <InnerContainer p={{ lg: '62px 70px 76px' }} flex={1}>
            <Stack height="100%" justifyContent="space-between">
              <SocialLinks
                facebookLink={contentfulFooter.facebookLink}
                instagramLink={contentfulFooter.instagramLink}
                linkedInLink={contentfulFooter.linkedInLink}
              />
              <AddressContainer>
                <AddressText variant="body1">
                  {contentfulFooter.address?.address}
                </AddressText>
              </AddressContainer>
            </Stack>
          </InnerContainer>
          <InnerContainer
            p={{ xs: '48px 0', lg: '46px 0 76px 66px' }}
            flexGrow={1}
          >
            <Stack flex={1} alignItems="stretch">
              <NewsletterForm
                ref={formRef}
                id="newsletter-form"
              ></NewsletterForm>
            </Stack>
          </InnerContainer>
        </Container>
      )}
    </footer>
  );
};

export default Footer;

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: '0 16px',
  overflow: 'hidden',
  maxWidth: '100vw',
  position: 'relative',

  [theme.breakpoints.up('lg')]: {
    padding: '0 64px',
    minHeight: 678,
    flexDirection: 'row',
  },
}));

const InnerContainer = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  '&:after': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    right: 'calc(50% - 50vw)',
    width: '100vw',
    height: 1,
    backgroundColor: theme.palette.primary.main,
  },

  '&:last-of-type': {
    '&:after': {
      display: 'none',
    },
  },

  [theme.breakpoints.up('lg')]: {
    '&:after': {
      bottom: 0,
      right: 0,
      width: 1,
      height: '100%',
    },
  },
}));

const GifContainer = styled(Stack)({
  width: 246,
  paddingBottom: 6,

  '& img': {
    background: `url(${LogoGif}), lightgray 0px 0px / 100% 100% no-repeat`,
    mixBlendMode: 'lighten',
  },
});

const LinkList = styled(List)({
  padding: '54px 0 0',
});

const LinkItem = styled(ListItem)({
  padding: '0 0 4px 0',

  '&:last-of-type': {
    padding: 0,
  },
});

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.footerLink.fontSize,
  padding: 0,
  textDecoration: 'none',

  '&:hover': {
    color: theme.palette.primary.yellow,
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: 'none',
    height: 'auto',
    borderRadius: 0,
  },
}));

const AddressContainer = styled(Box)(({ theme }) => ({
  padding: '48px 0',

  [theme.breakpoints.up('lg')]: {
    padding: 0,
  },
}));

const AddressText = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre',

  [theme.breakpoints.up('lg')]: {
    fontSize: '.9375rem',
  },
}));

const NewsletterForm = styled(Stack)({
  maxWidth: 646,
  flex: 1,
  overflow: 'visible',

  '#hs-form-iframe-0': {
    overflow: 'visible !important',
    minHeight: '100%',
  },
});
