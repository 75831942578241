import React from 'react';

export const MenuIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="28"
      height="11"
      viewBox="0 0 28 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <line
          id="Line 106"
          y1="1.25049"
          x2="28"
          y2="1.25049"
          stroke="#ECECEC"
        />
        <line
          id="Line 107"
          y1="10.2505"
          x2="28"
          y2="10.2505"
          stroke="#ECECEC"
        />
      </g>
    </svg>
  );
};
