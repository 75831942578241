import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        ...theme.typography.button,
        lineHeight: 'normal',
        maxWidth: 254,
        fontSize: 18,
        height: 45,
        textTransform: 'uppercase',
        borderRadius: theme.shape.borderRadius['58'],
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        border: 'none',
        color: theme.palette.text.secondary,
        cursor: 'pointer',

        '&:hover': {
          boxShadow: 'none',
          border: 'none',
          backgroundColor: theme.palette.primary.yellow,
        },

        '&:disabled': {
          cursor: 'not-allowed',
        },

        [theme.breakpoints.up('lg')]: {
          fontSize: 30,
          letterSpacing: 1.5,
          maxWidth: 370,
          height: 75,
          borderRadius: theme.shape.borderRadius['100'],
        },
      };
    },
  },
  variants: [
    {
      props: { variant: 'primary' },
      style: ({ theme }) => {
        return {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.secondary,
        };
      },
    },
    {
      props: { variant: 'secondary' },
      style: ({ theme }) => {
        return {
          backgroundColor: theme.palette.primary.green,
        };
      },
    },
    {
      props: { variant: 'tertiary' },
      style: ({ theme }) => {
        return {
          backgroundColor: theme.palette.primary.blue,
        };
      },
    },
    {
      props: { variant: 'link' },
      style: ({ theme }) => {
        return {
          fontSize: 'inherit',
          color: theme.palette.text.primary,
          fontWeight: 'inherit',
          textTransform: 'none',
          padding: 0,
          textDecoration: 'none',
          lineHeight: 'inherit',
          letterSpacing: 'normal',
          display: 'inline',
          backgroundColor: theme.palette.background.transparent,
          verticalAlign: 'initial',

          '&:hover': {
            backgroundColor: theme.palette.background.transparent,
            color: theme.palette.primary.yellow,
          },

          [theme.breakpoints.up('lg')]: {
            maxWidth: 'none',
            height: 'auto',
            borderRadius: 0,
            fontSize: 'inherit',
            letterSpacing: 'normal',
            lineHeight: 'inherit',
          },
        };
      },
    },
    {
      props: { variant: 'socialButton' },
      style: ({ theme }) => {
        return {
          width: 'fit-content',
          minWidth: 'fit-content',
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.transparent,

          '&:last-of-type': {
            padding: 0,
          },

          '&:hover': {
            backgroundColor: theme.palette.background.transparent,
            color: theme.palette.primary.yellow,

            svg: {
              fill: theme.palette.primary.yellow,
            },
          },
        };
      },
    },
  ],
};
