import { MuiTypography } from './typography';
import { MuiButton } from './button';
import { MuiCard, MuiCardActionArea } from './card';

export const components = {
  MuiTypography,
  MuiButton,
  MuiCard,
  MuiCardActionArea,
};
