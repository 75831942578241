import React from 'react';

export const StudionLogoVertical: React.ElementType<
  JSX.IntrinsicElements['svg']
> = ({ ...props }) => {
  return (
    <svg
      width="227"
      height="51"
      viewBox="0 0 227 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Layer_1" clipPath="url(#clip0_620_12256)">
        <path
          id="Vector"
          d="M0 33.0053H7.5804C8.11266 39.3761 11.8986 43.6977 20.8096 43.6977C28.4501 43.6977 30.7766 40.3793 30.7766 36.0664C30.7766 30.0213 26.1236 29.1639 17.4787 26.9688C10.0357 24.9795 1.85432 22.5873 1.85432 13.5583C1.85432 5.3268 8.23285 0.61084 18.4745 0.61084C29.3086 0.61084 36.3567 6.12423 37.0865 15.6848H29.506C28.7077 9.97417 25.454 6.85307 18.5346 6.85307C12.551 6.85307 9.49482 9.04814 9.49482 13.1639C9.49482 18.4715 14.4826 19.6033 20.595 21.1296C29.7722 23.4533 38.4085 25.6398 38.4085 35.6719C38.4085 43.8349 32.8885 49.9485 20.9899 49.9485C9.09133 49.9485 0.532259 43.6977 0 33.0053Z"
          fill="#ECECEC"
        />
        <path
          id="Vector_2"
          d="M48.6245 39.4448V20.5209H42.2803V15.1447H48.6245V4.25513H55.1403V15.1447H61.7249V20.5209H55.1403V39.642C55.1403 43.2262 56.1362 44.0236 58.9263 44.0236C60.0594 44.0236 60.5917 43.955 61.7163 43.7578V49.0054C60.0509 49.3398 58.9949 49.3998 57.5956 49.3998C52.0155 49.3998 48.6245 47.2733 48.6245 39.4448Z"
          fill="#ECECEC"
        />
        <path
          id="Vector_3"
          d="M67.6227 38.5789V15.1448H74.1386V36.7868C74.1386 41.3656 76.1302 44.4181 81.0494 44.4181C86.6295 44.4181 90.2265 41.0312 90.2265 35.9208V15.1362H96.7424V48.9969H90.2265V44.7439H90.0978C88.3035 47.3334 84.7752 49.9229 78.9289 49.9229C72.6791 49.9229 67.6313 46.3388 67.6313 38.5703L67.6227 38.5789Z"
          fill="#ECECEC"
        />
        <path
          id="Vector_4"
          d="M102.829 32.0792C102.829 20.9238 109.414 14.1499 118.05 14.1499C123.373 14.1499 126.626 16.6023 128.489 19.2603H128.618V1.53687H135.134V49.0052H128.618V45.0181H128.489C126.626 47.4104 123.373 49.9313 118.05 49.9313C109.405 49.9313 102.829 43.226 102.829 32.0706V32.0792ZM119.115 44.5637C124.961 44.5637 128.549 39.7191 128.549 32.0792C128.549 24.4393 124.961 19.5948 119.115 19.5948C112.736 19.5948 109.611 25.434 109.611 32.0792C109.611 38.7244 112.736 44.5637 119.115 44.5637Z"
          fill="#ECECEC"
        />
        <path
          id="Vector_5"
          d="M147.067 1.17676C149.17 1.17676 150.87 2.87451 150.87 4.97526C150.87 7.07601 149.17 8.77376 147.067 8.77376C144.963 8.77376 143.264 7.07601 143.264 4.97526C143.264 2.87451 144.963 1.17676 147.067 1.17676ZM143.779 14.0899H150.295V49.0139H143.779V14.0899Z"
          fill="#ECECEC"
        />
        <path
          id="Vector_6"
          d="M157.137 32.0794C157.137 21.5242 163.515 14.0901 174.152 14.0901C184.788 14.0901 191.038 21.4556 191.038 32.0194C191.038 42.5831 184.66 50.0086 174.092 50.0086C163.524 50.0086 157.137 42.7032 157.137 32.0794ZM174.152 44.6238C181.063 44.6238 184.256 39.1105 184.256 32.0794C184.256 25.0483 181.063 19.5349 174.152 19.5349C167.241 19.5349 163.919 24.9797 163.919 32.0794C163.919 39.1791 167.112 44.6238 174.152 44.6238Z"
          fill="#ECECEC"
        />
        <path
          id="Vector_7"
          d="M213.573 19.7236C207.993 19.7236 204.396 23.1105 204.396 28.2209V49.0054H197.88V15.1448H204.396V19.3977H204.525C206.319 16.8082 209.847 14.1501 215.694 14.1501C221.944 14.1501 226.991 17.7343 226.991 25.5028V49.0054H220.476V27.2948C220.476 22.7761 218.484 19.7236 213.565 19.7236H213.573Z"
          fill="#ECECEC"
        />
      </g>
      <defs>
        <clipPath id="clip0_620_12256">
          <rect width="227" height="51" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
