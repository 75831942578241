import React, { useState, useEffect, useRef } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { StudionLogoHorizontal } from '../icons';
import { Link } from 'gatsby';

interface Props {
  children: React.ReactNode;
}

const NavigationDesktop = ({ children }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useTheme();

  const padding = isScrolled
    ? `${theme.spacing(4.5)} ${theme.spacing(6.25)}`
    : `${theme.spacing(5.25)} ${theme.spacing(6.25)}`;
  const logoWidth = isScrolled ? 125 : 175;
  const logoHeight = isScrolled ? 25 : 35;

  useEffect(() => {
    window.addEventListener('scroll', updateScrollStatus);

    return () => {
      window.removeEventListener('scroll', updateScrollStatus);
    };
  }, []);

  const updateScrollStatus = () => {
    if (window !== undefined && containerRef.current) {
      const windowHeight = window.scrollY;
      const containerHeight = containerRef.current.scrollHeight;
      windowHeight > containerHeight
        ? setIsScrolled(true)
        : setIsScrolled(false);
    }
  };

  return (
    <Container ref={containerRef}>
      <MainNav
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={padding}
        component="nav"
        aria-label="Main Navigation"
      >
        <LogoLink to="/" aria-label="Link to Home">
          <StudionLogoHorizontal width={logoWidth} height={logoHeight} />
        </LogoLink>
        {children}
      </MainNav>
    </Container>
  );
};

export default NavigationDesktop;

const LogoLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  svg: {
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.short,
      easing: 'ease',
    }),
  },
}));

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shorter,
    easing: 'ease',
  }),

  '&:after': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    right: 'calc(50% - 50vw)',
    width: '100vw',
    height: 1,
    backgroundColor: theme.palette.primary.main,
  },
}));

const MainNav = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shorter,
    easing: 'ease',
  }),
}));
