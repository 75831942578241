import React from 'react';

export const PlayIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="189"
      height="189"
      viewBox="0 0 189 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 94.5C1.5 43.1382 43.1382 1.5 94.5 1.5C145.863 1.5 187.5 43.1382 187.5 94.5C187.5 145.862 145.862 187.5 94.5 187.5C43.1382 187.5 1.5 145.862 1.5 94.5Z"
        stroke="#E0FB61"
        strokeWidth="3"
      />
      <path
        d="M68.457 133.937L133.938 95.244L68.457 56.5508V133.937Z"
        stroke="#E0FB61"
        strokeWidth="3"
      />
    </svg>
  );
};
