import React from 'react';
import useNavigation from 'hooks/useNavigation';
import { useTheme, useMediaQuery, styled, Box } from '@mui/material';
import NavigationDesktop from '../Navigation/NavigationDesktop';
import NavigationMobile from '../Navigation/NavigationMobile';
import NavigationItems from '../Navigation/components/NavigationItems';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { contentfulNavigationMenu } = useNavigation();

  return (
    <HeaderComponent>
      <NavigationWrapper>
        {isMobile ? (
          <NavigationMobile>
            {contentfulNavigationMenu?.menuItems && (
              <NavigationItems navItems={contentfulNavigationMenu?.menuItems} />
            )}
          </NavigationMobile>
        ) : (
          <NavigationDesktop>
            {contentfulNavigationMenu?.menuItems && (
              <NavigationItems navItems={contentfulNavigationMenu?.menuItems} />
            )}
          </NavigationDesktop>
        )}
      </NavigationWrapper>
    </HeaderComponent>
  );
};

export default Header;

const HeaderComponent = styled('header')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.header,
  backgroundColor: theme.palette.primary.offBlack,
}));

const NavigationWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '93%',
  margin: '0 auto',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '80%',
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '90%',
  },

  [theme.breakpoints.up('xl')]: {
    maxWidth: 1568,
  },

  '&:after': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1,
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  '&:before': {
    content: "''",
    position: 'absolute',
    top: 0,
    right: 0,
    width: 1,
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));
