import React, { useState, useEffect } from 'react';
import {
  styled,
  Drawer,
  Box,
  Stack,
  IconButton,
  useTheme,
} from '@mui/material';
import { MenuIcon, StudionLogoHorizontal, CloseIcon } from '../icons';
import { Link } from 'gatsby';
import { globalHistory } from '@reach/router';

interface Props {
  children: React.ReactNode;
}

const NavigationMobile = ({ children }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setIsMenuOpen(false);
    });
  }, [isMenuOpen]);

  return (
    <Box component="nav" aria-label="Main Navigation">
      <ContainerComponent>
        <MobileActions>
          <Link to="/" aria-label="Link to Home">
            <StudionLogoHorizontal height={24} width={122} />
          </Link>
          <IconButton
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open Main Navigation Menu"
            aria-expanded={isMenuOpen}
            aria-hidden={isMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        </MobileActions>
      </ContainerComponent>

      <NavigationMenu open={isMenuOpen} anchor="left" variant="temporary">
        <MobileActions display="flex" justifyContent="space-between">
          <Link to="/" aria-label="Link to Home">
            <StudionLogoHorizontal
              color={theme.palette.primary.offBlack}
              height={24}
              width={122}
            />
          </Link>
          <IconButton
            onClick={() => setIsMenuOpen(false)}
            aria-label="Close Main Navigation Menu"
            aria-expanded={isMenuOpen}
            aria-hidden={!isMenuOpen}
          >
            <CloseIcon />
          </IconButton>
        </MobileActions>
        <Stack>{children}</Stack>
      </NavigationMenu>
    </Box>
  );
};

export default NavigationMobile;

const ContainerComponent = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: '50px 16px 26px',

  '&:after': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    right: 'calc(50% - 50vw)',
    width: '100vw',
    maxWidth: 'none',
    height: 1,
    backgroundColor: theme.palette.primary.main,
  },
}));

const MobileActions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const NavigationMenu = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.yellow,
    width: '100vw',
    padding: '42px 30px 0',
  },
}));
