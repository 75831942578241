import { Components, Theme } from '@mui/material';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      h5: 'h3',
      h6: 'h3',
      h1Small: 'h1',
    },
  },
  styleOverrides: {
    root: ({ theme }) => {
      return {
        color: theme.palette.text.primary,
      };
    },
  },
  variants: [
    {
      props: { variant: 'h1' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '3.125rem',
            lineHeight: 1.12,
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '4.375rem',
            lineHeight: 1.12,
          },
        };
      },
    },
    {
      props: { variant: 'h2' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '3.75rem',
          },
        };
      },
    },
    {
      props: { variant: 'h3' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '2.1875rem',
          },
        };
      },
    },
    {
      props: { variant: 'h4' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.875rem',
          },
        };
      },
    },
    {
      props: { variant: 'h5' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '2.0625',
          },
        };
      },
    },
    {
      props: { variant: 'h6' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.5625rem',
          },
        };
      },
    },
    {
      props: { variant: 'h1Small' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '2.5rem',
          },
        };
      },
    },
    {
      props: { variant: 'body1' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.5625rem',
          },
        };
      },
    },
    {
      props: { variant: 'body2' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.25rem',
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '1.5625rem',
          },
        };
      },
    },
    {
      props: { variant: 'body3' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.875rem',
          },
        };
      },
    },
    {
      props: { variant: 'bullet1' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.25rem',
          },
        };
      },
    },
    {
      props: { variant: 'caption' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.125rem',
          },
        };
      },
    },
    {
      props: { variant: 'quote1' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '2.3124rem',
          },
        };
      },
    },
    {
      props: { variant: 'quote2' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '2.5rem',
            lineHeight: 1.37,
          },
        };
      },
    },
    {
      props: { variant: 'quote3' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '2.8125rem',
            lineHeight: 1.39,
          },
        };
      },
    },
    {
      props: { variant: 'attribution' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.875rem',
          },
        };
      },
    },
    {
      props: { variant: 'pullQuote' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('md')]: {
            fontSize: '3.125rem',
          },
        };
      },
    },
    {
      props: { variant: 'tag' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.25rem',
            lineHeight: 1.12,
          },
        };
      },
    },
    {
      props: { variant: 'blogCta' },
      style: ({ theme }) => {
        return {
          [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
          },
        };
      },
    },
  ],
};
