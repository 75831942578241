export const palette = {
  primary: {
    main: '#ECECEC',
    blue: '#7A95FF',
    green: '#23F48B',
    yellow: '#E0FB61',
    offBlack: '#323338',
  },
  text: {
    primary: '#ECECEC',
    secondary: '#323338',
  },
  background: {
    default: '#323338',
    transparent: 'transparent',
  },
  overlay: {
    overlayVideo: 'rgba(0, 0, 0, .2)',
  },
};
