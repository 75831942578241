import React from 'react';
import { IconProps } from 'shared/types/index';
import { theme } from 'shared/styles/theme/theme';

export const StudionLogoHorizontal = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 175 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7733 35H12.7706V28.2049H32.7733C36.1691 28.2049 38.9297 25.4197 38.9297 21.9938C38.9297 18.5679 36.2814 15.7827 32.7733 15.7827C30.1855 15.7827 27.4422 17.9838 24.539 20.3113C21.0396 23.1183 17.4192 26.0212 12.8916 26.0212C5.66384 26.0168 0 20.3026 0 13.0062C0 5.70984 5.7848 0 12.8916 0H32.1815V6.79514H12.8916C9.49589 6.79514 6.73526 9.58032 6.73526 13.0062C6.73526 16.4321 9.38356 19.2173 12.8916 19.2173C15.069 19.2173 17.6309 17.16 20.3483 14.9851C23.8564 12.1694 27.8353 8.98319 32.7733 8.98319C40.0011 8.98319 45.6649 14.6974 45.6649 21.9894C45.6649 29.2814 39.8801 34.9956 32.7733 34.9956V35Z"
        fill={color}
      />
      <path
        d="M60.7645 21.3225H64.5793C64.8471 24.5609 66.7524 26.7577 71.2368 26.7577C75.0818 26.7577 76.2526 25.0709 76.2526 22.8785C76.2526 19.8057 73.911 19.3698 69.5605 18.254C65.8149 17.2428 61.6977 16.0267 61.6977 11.437C61.6977 7.25273 64.9076 4.85547 70.0617 4.85547C75.5138 4.85547 79.0607 7.65808 79.4279 12.518H75.6132C75.2114 9.61512 73.574 8.02857 70.0919 8.02857C67.0807 8.02857 65.5427 9.14439 65.5427 11.2365C65.5427 13.9345 68.0527 14.5099 71.1288 15.2857C75.7471 16.4669 80.0933 17.5784 80.0933 22.678C80.0933 26.8274 77.3153 29.9352 71.3275 29.9352C65.3396 29.9352 61.0237 26.7621 60.7559 21.3312L60.7645 21.3225Z"
        fill={color}
      />
      <path
        d="M85.2298 24.595V14.9754H82.0371V12.2425H85.2298V6.70703H88.5088V12.2425H91.8225V14.9754H88.5088V24.6952C88.5088 26.5171 89.01 26.9225 90.4141 26.9225C90.9843 26.9225 91.2522 26.8876 91.8181 26.7874V29.4549C90.98 29.6248 90.4486 29.6553 89.7444 29.6553C86.9363 29.6553 85.2298 28.5744 85.2298 24.595Z"
        fill={color}
      />
      <path
        d="M94.7949 24.1548V12.2426H98.074V23.2439C98.074 25.5714 99.0763 27.1231 101.552 27.1231C104.36 27.1231 106.17 25.4014 106.17 22.8037V12.2383H109.449V29.4506H106.17V27.2887H106.105C105.202 28.605 103.427 29.9213 100.485 29.9213C97.3395 29.9213 94.7992 28.0994 94.7992 24.1505L94.7949 24.1548Z"
        fill={color}
      />
      <path
        d="M112.508 20.8473C112.508 15.1767 115.821 11.7334 120.168 11.7334C122.846 11.7334 124.484 12.98 125.421 14.3312H125.486V5.32617H128.765V29.4557H125.486V27.4289H125.421C124.484 28.645 122.846 29.9264 120.168 29.9264C115.817 29.9264 112.508 26.518 112.508 20.8473ZM120.703 27.1935C123.645 27.1935 125.451 24.7309 125.451 20.8473C125.451 16.9638 123.645 14.5011 120.703 14.5011C117.493 14.5011 115.921 17.4694 115.921 20.8473C115.921 24.2253 117.493 27.1935 120.703 27.1935Z"
        fill={color}
      />
      <path
        d="M134.775 5.13867C135.834 5.13867 136.689 6.00169 136.689 7.06956C136.689 8.13743 135.834 9.00044 134.775 9.00044C133.717 9.00044 132.861 8.13743 132.861 7.06956C132.861 6.00169 133.717 5.13867 134.775 5.13867ZM133.121 11.7028H136.4V29.4556H133.121V11.7028Z"
        fill={color}
      />
      <path
        d="M139.842 20.8476C139.842 15.4821 143.052 11.7031 148.405 11.7031C153.757 11.7031 156.902 15.4472 156.902 20.8171C156.902 26.1869 153.692 29.9615 148.374 29.9615C143.056 29.9615 139.842 26.248 139.842 20.8476ZM148.405 27.2243C151.882 27.2243 153.489 24.4217 153.489 20.8476C153.489 17.2735 151.882 14.4709 148.405 14.4709C144.927 14.4709 143.255 17.2386 143.255 20.8476C143.255 24.4565 144.862 27.2243 148.405 27.2243Z"
        fill={color}
      />
      <path
        d="M168.243 14.5714C165.435 14.5714 163.625 16.2931 163.625 18.8908V29.4562H160.346V12.2439H163.625V14.4058H163.69C164.593 13.0895 166.368 11.7383 169.31 11.7383C172.455 11.7383 174.996 13.5602 174.996 17.5091V29.4562H171.717V18.4201C171.717 16.1231 170.714 14.5714 168.239 14.5714H168.243Z"
        fill={color}
      />
    </svg>
  );
};

StudionLogoHorizontal.defaultProps = {
  color: theme.palette.primary.main,
  height: 35,
  width: 175,
};
