import React from 'react';

export const CloseIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Layer_1" clipPath="url(#clip0_605_8560)">
        <path
          id="Vector"
          d="M37.4766 36.7695L12.7279 12.0208"
          stroke="#323338"
          strokeMiterlimit="10"
        />
        <path
          id="Vector_2"
          d="M12.0209 36.7695L36.7697 12.0208"
          stroke="#323338"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_605_8560">
          <rect
            width="35"
            height="35"
            fill="white"
            transform="translate(24.7488) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
