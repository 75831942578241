import React from 'react';

export const FacebookIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Facebook</title>
      <path
        d="M35.6405 17.9291C35.6405 8.02715 27.6621 0 17.8202 0C7.97839 0 0 8.02715 0 17.9291C0 26.878 6.51657 34.2954 15.0358 35.6405V23.1118H10.5112V17.9291H15.0358V13.9791C15.0358 9.48564 17.6963 7.00357 21.7668 7.00357C23.7166 7.00357 25.7558 7.35375 25.7558 7.35375V11.766H23.5088C21.2952 11.766 20.6047 13.1482 20.6047 14.566V17.9291H25.547L24.7569 23.1118H20.6047V35.6405C29.1239 34.2954 35.6405 26.8783 35.6405 17.9291Z"
        fill="currentColor"
      />
    </svg>
  );
};
