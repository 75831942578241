import React from 'react';
import { Slice } from 'gatsby';
import { styled } from '@mui/material';
import Footer from 'shared/components/Footer/Footer';
import Header from 'shared/components/Header/Header';

type Props = {
  children: React.ReactNode;
  pageContext: {
    layout: string;
  };
};

export default function Layout({ children, pageContext }: Props) {
  return (
    <LayoutComponent>
      <Header />
      {children}
      <Footer />
    </LayoutComponent>
  );
}

const LayoutComponent = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '100vw',
  margin: '0 auto',

  '& main, footer': {
    position: 'relative',
    width: '100%',
    maxWidth: '93%',
    margin: '0 auto',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '80%',
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%',
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 1568,
    },

    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: 1,
      height: '100%',
      zIndex: theme.zIndex.default,
      backgroundColor: theme.palette.primary.main,
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      right: 0,
      width: 1,
      height: '100%',
      zIndex: theme.zIndex.default,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
