import { graphql, useStaticQuery } from 'gatsby';

const useFooter = () => {
  const footerData: Queries.FooterObjectQuery = useStaticQuery(graphql`
    query FooterObject {
      contentfulFooter {
        linkedInLink
        instagramLink
        facebookLink
        hubSpotFormFormId
        hubSpotFormPortalId
        hubSpotFormRegion
        address {
          address
        }
        links {
          ... on ContentfulContactPage {
            pageName
            slug
          }
          ... on ContentfulPolicyPage {
            slug
            pageName
          }
        }
      }
    }
  `);

  return footerData;
};

export default useFooter;
